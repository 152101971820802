import React, { useContext } from "react"
import TimeLineHistory from "./TimeLineHistory"
import { LoadingContext } from "../../components/context/LoadingContext"
import i18n from "i18next"

const History = () => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className="mobile:w-full w-full flip-card">
      <div className="flip-card-inner2 ">
        <div
          className={
            windowWidth >= 1025
              ? "sectionHistory desktop:flex"
              : "backgorundHistory"
          }
        >
          {windowWidth >= 1025 ? (
            <div className="divTitleHistory">
              <p className="titleOurHistory">
                {i18n.t("home.history.subtitle")}
              </p>
              <p className="titleOurHistory2">
                {i18n.t("home.history.title")}{" "}
              </p>
              <p className="titleOurHistory2">
                {i18n.t("home.history.title2")}
              </p>
            </div>
          ) : (
            <div className="divTitleHistoryMobile">
              <p className="divTimeLimeHistoryMobile">
                {i18n.t("home.history.subtitle")}
              </p>
              <p className="titleOurHistory2">
                {i18n.t("home.history.title")}{" "}
              </p>
              <p className="titleOurHistory2">
                {i18n.t("home.history.title2")}
              </p>
            </div>
          )}
          <TimeLineHistory />
        </div>
      </div>
    </div>
  )
}

export default History
