import React from "react"
import i18n from "i18next"
import CustomerSlide from "./customerSlide"
import avianca from "./../../images/home/Customers/image146.jpg"
import dafiti from "./../../images/home/Customers/image145.jpg"
import bbva from "./../../images/home/Customers/image141.jpg"
import chub from "./../../images/home/Customers/image140.jpg"
import colpatria from "./../../images/home/Customers/image142.jpg"
import uala from "./../../images/home/Customers/image139.jpg"

const group4 = [avianca, dafiti, bbva, chub, colpatria, uala]

const Customers = () => {
  const attributesMainTitle = {
    id: "CustomersDNA",
    title: i18n.t("pageourdna.customer"),
    styles: {
      bulletColor: "light-blue",
      textColor: "dark-blue",
      fontSizeMobile: "36",
      fontSize: "44",
      alignMobile: "left",
      alignDesktop: "center",
      marginL: "20",
    },
  }

  return (
    <div className="customers__container">
      <CustomerSlide slidesToShowMobile={1} items={group4} />
    </div>
  )
}

export default Customers
