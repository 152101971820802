import React, { useContext } from "react"
import { LoadingContext } from "../../components/context/LoadingContext"
import i18n from "i18next"
import ContactUsForm from "./ContactUsForm"

const Counts = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const arrayCount = [
    {
      count: "+204",
      texto: "Proyectos completados",
      textoEnglish: "Projects completed",
    },
    {
      count: "+50",
      texto: "Clientes exclusivos",
      textoEnglish: "eXclusive clients",
    },
    {
      count: "+600",
      texto: "Profesionales de IT involucrados",
      textoEnglish: "IT Professionals involved",
    },
    {
      count: "+24",
      texto: "Proyectos Activos",
      textoEnglish: "Active Projects",
    },
  ]

  return (
    <div
      className={
        windowWidth >= 1025
          ? "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card paddingArticles sectionCounts"
          : "mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card paddingArticles sectionCountsMobile"
      }
    >
      <div className="flip-card-inner2 desktop:ml-16 mt-24">
        <div className="desktop:flex  contentApply">
          {windowWidth >= 1025 ? (
            <div className="countTextCount">
              <div className="flex">
                <p className="textCounts">
                  {i18n.t("home.formContactUs.title")}
                </p>
                <p className="textCounts colorEx">
                  {i18n.t("home.formContactUs.title2")}
                </p>
                <p className="textCounts">
                  {i18n.t("home.formContactUs.title3")}
                </p>
                <p className="ml-2 textCounts">
                  {i18n.t("home.formContactUs.title31")}
                </p>
              </div>
              <p className="textCounts">
                {i18n.t("home.formContactUs.title4")}
              </p>
            </div>
          ) : (
            <div className="countTextCountMobile">
              <div className="flex">
                <p className="textCounts">
                  {i18n.t("home.formContactUs.title")}
                </p>
                <p className="textCounts colorEx">
                  {i18n.t("home.formContactUs.title2")}
                </p>
                <p className="textCounts">
                  {i18n.t("home.formContactUs.title3")}{" "}
                </p>
              </div>
              <p className="textCounts">
                {" "}
                {i18n.t("home.formContactUs.title31")}
              </p>
              <p className="textCounts">
                {i18n.t("home.formContactUs.title4")}
              </p>
            </div>
          )}

          {windowWidth >= 1025 ? (
            <div className="desktop:flex countsNumber">
              {arrayCount.map((item, i) => {
                return (
                  <div className="mr-12 ml-12">
                    <p className="textNumber">{item.count}</p>
                    {i18n.language == "en" ? (
                      <p className="textExplication">{item.textoEnglish}</p>
                    ) : (
                      <p className="textExplication">{item.texto}</p>
                    )}
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="flex flex-wrap">
              {arrayCount.map((item, i) => {
                return (
                  <div
                    className={
                      item.count == "+600" ? "mt-8 ml-6" : "mt-8 ml-12"
                    }
                  >
                    <p className="textNumber">{item.count}</p>
                    {i18n.language == "en" ? (
                      <p
                        className={
                          item.count == "+600"
                            ? "textExplication textExMobile2"
                            : "textExplication textExMobile"
                        }
                      >
                        {item.textoEnglish}
                      </p>
                    ) : (
                      <p
                        className={
                          item.count == "+600"
                            ? "textExplication textExMobile2"
                            : "textExplication textExMobile"
                        }
                      >
                        {item.texto}
                      </p>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <ContactUsForm />
      </div>
    </div>
  )
}

export default Counts
