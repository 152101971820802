export default function limpiarTexto(texto) {
  // Eliminar caracteres: (, . ; : _ - ? ¿ )
  let sinCaracteresEspeciales = texto.replace(/[(),.;:_\-?¿]/g, "")

  // Quitar tildes
  let sinTildes = sinCaracteresEspeciales.replace(/[áéíóúüÁÉÍÓÚÜ]/g, function(
    match
  ) {
    const mapaTildes = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      ü: "u",
    }
    return mapaTildes[match] || match
  })

  // Eliminar números
  let sinNumeros = sinTildes.replace(/[0-9]/g, "")
  return sinNumeros
    .split(" ")
    .join("-")
    .toLowerCase()
}

