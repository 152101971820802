import React, { useContext } from "react"
import { LoadingContext } from "../../components/context/LoadingContext"

const TargetExperts = ({ imagesArr, titleArr }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <>
      {windowWidth >= 1025 ? (
        <>
          <p className="titleStandarAgile titleSectionStack">{titleArr}</p>
          <div className="flex targetExperts mt-12 ">
            {imagesArr.slice(0, 4).map((item, i) => {
              return (
                <div
                  className={
                    windowWidth > 1700
                      ? "contentTargetExpertsResponsive mr-8"
                      : "contentTargetExperts mr-8"
                  }
                >
                  <div className="">
                    <img
                      src={item.image}
                      alt="...."
                      className="imgCardExpert"
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex targetExperts mt-12 ">
            {imagesArr.slice(4, 8).map((item, i) => {
              return (
                <div
                  className={
                    windowWidth > 1700
                      ? "contentTargetExpertsResponsive2 mr-8"
                      : "contentTargetExperts mr-8"
                  }
                >
                  <div className="">
                    <img
                      src={item.image}
                      alt="...."
                      className={
                        item.texto == "2"
                          ? "imgCardExpertIonic"
                          : "imgCardExpert"
                      }
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <>
          <p className="titleStandarAgile mt-8 ml-4">{titleArr}</p>
          <div className=" targetExpertsMobile mt-12 ">
            {imagesArr.map((item, i) => {
              return (
                <div className="mb-8">
                  <div className="contentTargetExpertsMobile ">
                    <div className="">
                      <img
                        src={item.image}
                        alt="...."
                        className="imgCardExpert"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </>
  )
}

export default TargetExperts
