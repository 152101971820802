import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../../components/context/LoadingContext"
import { Link } from "gatsby"

const CoreSolution = ({ id, title, subtitle, subtitle2, arrayitems }) => {
  const { windowWidth } = useContext(LoadingContext)

  const imgStyle2 = {
    maxWidth: "6rem",
    maxHeight: "5.5rem",
  }

  return (
    <div
      className={`desktop:p-20 mobile:pt-24 mobile:pb-6 mobile:pl-6 mobile:pr-6`}
    >
      {windowWidth >= 1025 ? (
        <>
          <div className="flex ml-8">
            <div>
              <p className="subTitleOurServices">
                {i18n.t("home.ourServices.title")}
              </p>
              {i18n.language == "en" ? (
                <div className="flex">
                  <p className="titleOurServices">E</p>
                  <p className="titleOurServices colorEx">x</p>
                  <p className="titleOurServices">ponential solutions</p>
                </div>
              ) : (
                <div className="flex">
                  <p className="titleOurServices">Soluciones e</p>
                  <p className="titleOurServices colorEx">x</p>
                  <p className="titleOurServices">ponenciales</p>
                </div>
              )}
            </div>
            <div className="ml-auto flex">
              <Link to={"/x-media-success-stories"}>
                <button
                  className="contentButtomViewAllServices ml-4 desktop:mr-4 "
                  style={{ alignSelf: "center" }}
                >
                  <p className="textButtomGetTouch">
                    {i18n.t("home.ourServices.bottomStories")}
                  </p>
                </button>
              </Link>
              <Link to={"/service-expansion"}>
                <button
                  className="contentButtomViewAll desktop:mr-8 "
                  style={{ alignSelf: "center" }}
                >
                  <p className="colorTextAllServices">
                    {i18n.t("home.ourServices.bottomServices")}
                  </p>
                </button>
              </Link>
            </div>
          </div>

          <p className="textCoreSolutionsSeccion desktop:ml-8 bigdesktop:ml-4 desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
            {i18n.t("home.ourServices.text")}
          </p>
        </>
      ) : (
        <>
          <div className="">
            <div>
              <p className="subTitleOurServices">
                {i18n.t("home.ourServices.title")}
              </p>
              {i18n.language == "en" ? (
                <>
                  <div className="flex">
                    <p className="titleOurServices">E</p>
                    <p className="titleOurServices colorEx">x</p>
                    <p className="titleOurServices">ponential</p>
                  </div>
                  <p className="titleOurServices">solutions</p>{" "}
                </>
              ) : (
                <>
                  <p className="titleOurServices">Soluciones</p>
                  <div className="flex">
                    <p className="titleOurServices">e</p>
                    <p className="titleOurServices colorEx">x</p>
                    <p className="titleOurServices">ponenciales</p>
                  </div>
                </>
              )}
            </div>
          </div>

          <p className="textCoreSolutionsMobile text-left desktop:text-20 mobile:text-16 desktop:text-left  mb-8 mobile:px-6">
            {i18n.t("home.ourServices.text")}
          </p>

          {windowWidth < 1025
            ? arrayitems.map((item, i) => {
                return (
                  <div key={i.toString()} className="flex mb-6">
                    <div className="text-left w-auto contentDivCoreMobile p-10">
                      <div className="flex justify-center mb-2">
                        <img
                          src={item.image}
                          style={imgStyle2}
                          className="mr-1 mt-1"
                        />
                      </div>
                      <div className="w-full text-left">
                        {i18n.language == "en" ? (
                          <p className="pr-2 pl-2 textDivCore desktop:text-20 mobile:text-16">
                            {item.textEnglish}
                          </p>
                        ) : (
                          <p className="pr-2 pl-2 textDivCore desktop:text-20 mobile:text-16">
                            {item.texto}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })
            : null}

          <div className="ml-auto ">
            <Link to={"/service-expansion"}>
              <button
                className="contentButtomViewAllMobile desktop:mr-8 bigdesktop:mr-40"
                style={{ alignSelf: "center" }}
              >
                <p className="colorTextAllServicesMobile">
                  {i18n.t("home.ourServices.bottomServices")}
                </p>
              </button>
            </Link>
            <Link to={"/x-media-success-stories"}>
              <button
                className="contentButtomViewAllServicesMobile desktop:mr-4 bigdesktop:mr-40 mt-6"
                style={{ alignSelf: "center" }}
              >
                <p className="textbottom">
                  {i18n.t("home.ourServices.bottomStories")}
                </p>
              </button>
            </Link>
          </div>
        </>
      )}

      <div className="desktop:flex desktop:ml-8 bigdesktop:ml-4 contenfImgCoreSolutionResponsive">
        {windowWidth >= 1025
          ? arrayitems.map((item, i) => {
              return (
                <div key={i.toString()} className=" mb-6">
                  <div className="contentDivCore">
                    <div className="flex justify-center mb-2 mt-4 ">
                      <img
                        src={item.image}
                        style={imgStyle2}
                        className="mr-1 mt-1"
                      />
                    </div>
                    <div className="flex flew-row text-left w-auto ">
                      <div className="w-full text-left">
                        {i18n.language == "en" ? (
                          <p className="pr-2 pl-2 textDivCore desktop:text-20">
                            {item.textEnglish}
                          </p>
                        ) : (
                          <p className="pr-2 pl-2 textDivCore desktop:text-20">
                            {item.texto}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          : null}
      </div>

      <br />
    </div>
  )
}

export default CoreSolution
