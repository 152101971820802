import React, { useState, useContext } from "react"
import columnImageRight from "./../../images/home/playerVideo.png"
import SharedBanner from "../shared-banner"

const BannerHomeOp = ({ scrollForm }) => {
  const [bannerHeight, setBannerHeight] = useState(0)
  const bannerHeightFunc = height => {
    setBannerHeight(height)
  }
  return (
    <SharedBanner
      scrollForm={scrollForm}
      callbackHeight={heightTest => bannerHeightFunc(heightTest)}
      bannerImageURL={columnImageRight}
      flag={false}
      clase={"text-black"}
    />
  )
}

export default BannerHomeOp
