import React, { Component } from "react";
import i18n from "i18next";
import Slider from "react-slick";
import ScrollTrigger from "react-scroll-trigger";

import Slide from "../shared/certifications/Slide";




export default class CustomerSlide extends Component {

  render() {

    const settings = {
      className: "center",
      loop: true,
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 4,
      initialSlide: 0,
      dotsClass: 'slick-dots blue-dark-slide-dots--small hidden-desktop__dot',

      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            loop: true,
            autoplay: true,
            autoplayTimeout: 2500,
            smartSpeed: 2000,
            infinite: true,
            slidesToScroll: 1,
            initialSlide: 0,
            dots: false
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: this.props.slidesToShowMobile,
            loop: true,
            autoplay: true,
            autoplayTimeout: 1000,
            smartSpeed: 2000,
            infinite: true,
            slidesToScroll: 1,
            initialSlide: 0,
            dots: true
          }
        }
      ]
    };

    function onEnterTitlesViewPort() {
      document
        .getElementById("divjoinus")
        .classList.add("main-title--animation");
    }
  
    function onExitTitlesViewPort() {
      document
        .getElementById("title")
        .classList.remove("main-title--animation");
    }

    return (
      <div className="px-8 mobile:mt-10 desktop:mt-16 boxCertificaction">
        <div className="-mx-8 ">
          <div className="mobile:w-full  mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
           
              <p className="titleCompanies">{i18n.t("home.ourServices.titleCompanies")}</p>              
           
          </div>
        </div>

        <div className="flex flex-wrap -mx-8">
          <div className="mobile:w-full landscape:w-full tablet:w-full desktop:w-full pr-8 pl-4">
          <div className="desktop:flex contentCompanies">
              {this.props.items.map((item, key) => {
                return <Slide
                  image={item}
                  key={key}
                />
              })}
          </div>
          </div>
        </div>

        
      </div>
    );
  }
}