import React from 'react';

const Slide = ({image, title, description}) => {

    return (
        <div className="apps___slide-wrapper mobile:pb-16 mobile:pt-4">
            <div className="apps___slide-image">   
                <img src={image} alt="" />             
            </div>
            <div className="apps___slide-description">
                <p className="apps___slide-description-text">{description}</p>
            </div>
        </div>
    );
};

export default Slide;