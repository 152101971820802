import React, { useContext } from "react"
import Timeline from "@material-ui/lab/Timeline"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import { LoadingContext } from "../../components/context/LoadingContext"
import i18n from "i18next"

const TimeLineHistory = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <>
      {windowWidth >= 1025 ? (
        <div className="divTimeLimeHistory">
          <Timeline align="alternate" className="timeLineHistoryComponent">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLine">
                  <p className="textYearTime">
                    {i18n.t("home.history.date1")}{" "}
                  </p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text1")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date2")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text2")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date3")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text3")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date4")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text4")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date5")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text5")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date6")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text6")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date7")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text7")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLine">
                  <p className="textYearTime">{i18n.t("home.history.date8")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text8")}
                  </p>
                  <p className="textDescritionTime2">
                    {i18n.t("home.history.text9")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      ) : (
        <div className="divTimeLimeHistoryMobile pb-24">
          <Timeline
            align="alternate"
            className="timeLineHistoryComponentMobile"
          >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLineMobile">
                  <p className="textYearTime">
                    {i18n.t("home.history.date1")}{" "}
                  </p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text1")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date2")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text2")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date3")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text3")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date4")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text4")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date5")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text5")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date6")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text6")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventRightTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date7")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text7")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent>
                <div className="eventLeftTimeLineMobile">
                  <p className="textYearTime">{i18n.t("home.history.date8")}</p>
                  <p className="textDescritionTime">
                    {i18n.t("home.history.text8")}
                  </p>
                  <p className="textDescritionTime2">
                    {" "}
                    {i18n.t("home.history.text9")}
                  </p>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      )}
    </>
  )
}

export default TimeLineHistory
