import React, { Fragment, useContext } from "react"
import Form from "react-bootstrap/Form"
import { PopupboxContainer } from "react-popupbox"
import i18n from "i18next"
import Button from "react-bootstrap/Button"
import {
  getInterest,
  getProfilesAugmentation,
  getProfilesHunting,
} from "./../../utils/api.js"
import { withNamespaces } from "react-i18next"
import PrivacyPolicy from "../shared/privacyPolicy/PrivacyPolicy.js"
import { LoadingContext } from "../../components/context/LoadingContext"
import Swal from "sweetalert2"
import { sendContacUs } from "./../../utils/api.js"
import { Link } from "gatsby"

class ContactUsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formCompany: {
        Nombre: "",
        Apellido: "",
        Correo: "",
        Telefono: "",
        Mensaje: "",
      },
      selectionOptions: [],
      checkCompany: false,
      openPrivacyPolicy: false,
      loading: false,
      windowWidth: LoadingContext,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckChangeCompany = this.handleCheckChangeCompany.bind(this)
    this.setPrivacy = this.setPrivacy.bind(this)
  }
  setPrivacy() {
    this.setState({ openPrivacyPolicy: false })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (
      this.state.formCompany.Nombre !== "" &&
      this.state.formCompany.Apellido !== "" &&
      this.state.formCompany.Correo !== "" &&
      this.state.formCompany.Telefono !== "" &&
      this.state.formCompany.Mensaje !== ""
    ) {
      if (this.state.checkCompany === true) {
        if (expression.test(this.state.formCompany.Correo)) {
          this.setState({ loading: true })
          await sendContacUs(this.state.formCompany)
            .then(async res => {
              this.setState({ loading: false })
              await this.openPopupboxSuccess()
              this.resetForms()
            })
            .catch(err => this.openPopupboxError())
        } else {
          this.openPopupboxEmail()
        }
      } else {
        this.openPopupboxCheck()
      }
    } else {
      this.openPopupboxEmpty()
    }
  }

  handleChange(event) {
    event.preventDefault()
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState(prevState => ({
      formCompany: {
        ...prevState.formCompany,
        [name]: value,
      },
    }))
  }

  handleCheckChangeCompany(event) {
    const target = event.target
    const value = target.name === "checkCompany" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  resetForms() {
    this.setState(prevState => ({
      formCompany: {
        Nombre: "",
        Apellido: "",
        Correo: "",
        Telefono: "",
        Mensaje: "",
      },
      selectionOptions: [],
      checkCompany: false,
    }))
  }

  componentDidMount() {
    if (this.props.type === "HUNTING") {
      this.getHuntingProfiles()
    } else if (this.props.type === "AUGMENTATION") {
      this.getAugmentationProfiles()
    } else {
      this.getInterestSelection()
    }
  }

  getInterestSelection() {
    getInterest(this.props.i18n.language)
      .then(res => {
        this.setState({
          selectionOptions: res.data.data,
        })
      })
      .catch(err => console.log(err))
  }

  getHuntingProfiles() {
    getProfilesHunting(this.props.i18n.language)
      .then(res => {
        this.setState({
          selectionOptions: res.data.data,
        })
      })
      .catch(err => console.log(err))
  }

  getAugmentationProfiles() {
    getProfilesAugmentation(this.props.i18n.language)
      .then(res => {
        this.setState({
          selectionOptions: res.data.data,
        })
      })
      .catch(err => console.log(err))
  }

  openPopupboxCheck() {
    Swal.fire("", i18n.t("home.alertcheckbox"), "")
  }

  openPopupboxEmpty() {
    Swal.fire("", i18n.t("home.alertempty"), "")
  }

  openPopupboxEmail() {
    Swal.fire("", i18n.t("home.alertwrongemail"), "")
  }

  openPopupboxSuccess() {
    Swal.fire("", i18n.t("home.alertformsend"), "")
  }

  openPopupboxError() {
    Swal.fire("", i18n.t("home.alertformerror"), "")
  }

  render() {
    // const {t} = useTranslation();
    const popupboxConfig = {
      titleBar: {
        enable: true,
      },
      fadeIn: true,
      fadeInSpeed: 500,
    }

    return (
      <Fragment>
        {this.state.openPrivacyPolicy ? (
          <PrivacyPolicy setOpenPrivacyPolicy={this.setPrivacy} />
        ) : null}

        <div
          className="relative overflow-hidden tablet:px-8  pt-8  form-contact"
          style={{ marginBottom: "-2px" }}
        >
          <div className="flex elementDesktop mobile:flex-wrap desktop:pl-12 desktop:pr-12 mobile:pl0 mobile:pr0">
            <div className="mt-16  w-full ">
              <div className="desktop:ml-16">
                <div className="min-h-full formContact desktop:p-20 mobile:p-8">
                  <Form id="letsTalkForm">
                    <div className="mobile:ml-4 mobile:mr-4 mobile:mt-12 mobile:mb-16 boxTitleForm2 desktop:mt-2">
                      <p className="titleForm desktop:text-44 landscape:text-44 mobile:text-24">
                        {i18n.t("home.titleFormTalk")}
                      </p>
                    </div>

                    <div className="flex mobile:flex-col desktop:flex-row desktop:mb-10">
                      <div className="mobile:w-full desktop:w-1/2 h-12 mr-10 mobile:mb-4">
                        <Form.Group
                          controlId="formBasicName"
                          className="text-left"
                        >
                          <Form.Label className="labelForm text-14 mobile:mt-1">
                            {i18n.t("home.formname")}
                          </Form.Label>
                          {i18n.language == "en" ? (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Nombre}
                              name="Nombre"
                              placeholder="Enter your first name"
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Nombre}
                              name="Nombre"
                              placeholder="Ingresa tu nombre"
                            />
                          )}
                        </Form.Group>
                      </div>
                      <div className="mobile:w-full desktop:w-1/2 h-12 mobile:mt-8 desktop:mt-0 mobile:mb-4">
                        <Form.Group
                          controlId="formBasicSurname"
                          className="text-left"
                        >
                          <Form.Label className="labelForm text-14 mobile:mt-1">
                            {i18n.t("home.formcompanyname")}
                          </Form.Label>
                          {i18n.language == "en" ? (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Apellido}
                              name="Apellido"
                              placeholder="Enter your last name"
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Apellido}
                              name="Apellido"
                              placeholder="Ingresa tu apellido"
                            />
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="flex mobile:flex-col desktop:flex-row mb-10">
                      <div className="mobile:w-full desktop:w-1/2 h-12 mobile:mt-8 desktop:mt-0 mr-10 mobile:mb-4">
                        <Form.Group
                          controlId="formBasicPhone"
                          className="text-left"
                        >
                          <Form.Label className="labelForm text-14 mobile:mt-1">
                            {i18n.t("home.formphonecity")}
                          </Form.Label>
                          {i18n.language == "en" ? (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Telefono}
                              name="Telefono"
                              placeholder="Enter your phone number"
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Telefono}
                              name="Telefono"
                              placeholder="Ingresa tu número de contacto"
                            />
                          )}
                        </Form.Group>
                      </div>
                      <div className="mobile:w-full desktop:w-1/2 h-12 mobile:mt-8 desktop:mt-0 mobile:mb-4">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="text-left"
                        >
                          <Form.Label className="labelForm text-14 mobile:mt-1">
                            {i18n.t("home.formemail")}
                          </Form.Label>
                          {i18n.language == "en" ? (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Correo}
                              name="Correo"
                              placeholder="Enter your email"
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Correo}
                              name="Correo"
                              placeholder="Ingresa tu email"
                            />
                          )}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="flex mobile:flex-col desktop:flex-row w-full text-left mobile:mb-4">
                        <Form.Group
                          controlId="formBasicSelectMessage"
                          className="mobile:w-full  desktop:mt-0 text-left form-group"
                        >
                          <Form.Label className="labelForm text-14 mobile:mt-1">
                            {i18n.t("home.formmessage")}
                          </Form.Label>
                          {i18n.language == "en" ? (
                            <Form.Control
                              as="textarea"
                              rows="6"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Mensaje}
                              name="Mensaje"
                              placeholder="Enter your message"
                            />
                          ) : (
                            <Form.Control
                              as="textarea"
                              rows="6"
                              onChange={this.handleChange}
                              value={this.state.formCompany.Mensaje}
                              name="Mensaje"
                              placeholder="Ingresa tu mensaje"
                            />
                          )}
                        </Form.Group>
                      </div>
                      <div className="mt-4">
                        <Form.Group
                          className="mobile:w-full form-group flex"
                          controlId="formBasicSelectInterest"
                        >
                          <label
                            className="checkbox-label--home-1 topCheck font-avenir-medium text-blue_dark text-12 relative mobile:mt-8 desktop:mt-0"
                            htmlFor="companyCheck"
                          >
                            <input
                              id="companyCheck"
                              type="checkbox"
                              checked={this.state.checkCompany}
                              onChange={e => this.handleCheckChangeCompany(e)}
                              name="checkCompany"
                            />
                            <span className="checkbox-custom--home-1"></span>
                            <div
                              style={{
                                marginLeft: "25px",
                                fontFamily: "AvenirBook",
                              }}
                            >
                              {i18n.t("home.formcheck")}{" "}
                              <Link to="/privacy-policy">
                                <a style={{ borderBottom: "1px solid" }}>
                                  {i18n.t("home.formcheckData")}
                                </a>
                              </Link>
                            </div>
                          </label>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="flex mt-8 mb-4">
                      <div className="desktop:m-auto mobile:w-full">
                        <Button
                          className="buttonSubmit desktop:w-48 mobile:w-full"
                          variant="primary"
                          type="button"
                          onClick={this.handleSubmit}
                          disabled={this.state.loading}
                        >
                          {" "}
                          {this.state.loading === true ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            i18n.t("home.formbutton")
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupboxContainer {...popupboxConfig} />
      </Fragment>
    )
  }
}

export default withNamespaces()(ContactUsForm)
