import React, { useContext } from "react"
import liImage from "../../images/home/ImageOurExpertise.jpg"
import liImage2 from "../../images/home/ImageOurExpertise2.jpg"
import { LoadingContext } from "./../context/LoadingContext"
import i18n from "i18next"

const OurExpertiseHome = ({ scrollForm }) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className="desktop:flex desktop:flex-wrap desktop:px-6 contetnBack mt-32 mobile:pt-8">
      <div className="mb-4 desktop:ml-20 mt-24 ">
        <div
          className={
            windowWidth < 1025
              ? "ml-4 leading-tight-desktop font-responsive-cards--desktop mt-4 text-16 gap-5"
              : "flex ml-4 leading-tight-desktop font-responsive-cards--desktop mt-4 text-16 gap-5"
          }
        >
          <div>
            <p className="textWhyUsHome">
              {i18n.t("home.ourExpertiseHome.sectionTitle")}
            </p>
          </div>

          <div>
            <p className="textWhyUsHome">
              {i18n.t("home.ourExpertiseHome.sectionTitle1")}
              <span className="textWhyUsHomeBlue">
                {i18n.t("home.ourExpertiseHome.sectionTitle2")}
              </span>
              {i18n.t("home.ourExpertiseHome.sectionTitle3")}
            </p>
          </div>
        </div>

        <p
          className={
            windowWidth >= 1025
              ? "text_OurExpertise2"
              : "text_OurExpertise2Mobile"
          }
        >
          {i18n.t("home.ourExpertiseHome.text")}
        </p>
      </div>
      <div
        className={
          windowWidth >= 1025
            ? "desktop:flex contentSecundaryOurExpertise justifyAround desktop:mt-8 paddingSeccionExpertise"
            : "desktop:flex contentSecundaryOurExpertise desktop:mt-8"
        }
      >
        {windowWidth >= 1025 ? (
          <>
            <div className={windowWidth >= 1700 ? "widthImageWhy" : ""}>
              <div className="text-left leading-tight-desktop font-responsive-cards--desktop mt-4 text-16">
                {i18n.language == "en" ? (
                  <>
                    {" "}
                    <div className="flex">
                      <p className="textOurExpertiseHome">
                        Let's Build your ne
                      </p>
                      <p className="textOurExpertiseHomeBlue">x</p>
                      <p className="textOurExpertiseHome">t IT </p>
                    </div>
                    <p className="textOurExpertiseHome">project</p>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="flex">
                      <p className="textOurExpertiseHome">
                        Construyamos tu pró
                      </p>
                      <p className="textOurExpertiseHomeBlue">x</p>
                      <p className="textOurExpertiseHome">imo</p>
                    </div>
                    <p className="textOurExpertiseHome">proyecto de TI</p>
                  </>
                )}
              </div>

              <p className="text_OurExpertise">
                {i18n.t("home.ourExpertiseHome.titleProject")}
              </p>
              <img
                src={liImage}
                alt="Clock"
                className="mt-8 contentsResponsiveimgOur"
              />
            </div>
            <div
              className={windowWidth >= 1700 ? "widthImageWhy ml-20" : "ml-20"}
            >
              <img
                src={liImage2}
                alt="Clock"
                className="mb-8 contentsResponsiveimgOur"
              />
              <p className="textOurExpertiseHome text-left leading-tight-desktop font-responsive-cards--desktop mt-4 text-16">
                {i18n.t("home.ourExpertiseHome.titleCompany")}
              </p>
              <p className="text_OurExpertise">
                {i18n.t("home.ourExpertiseHome.textCompany")}
              </p>

              <div className="mt-8">
                <button
                  onClick={() => window.location.href = 'https://calendly.com/sebastianjimenez/30min?back=1&month=2024-04'}
                  className="contentButtomViewAllServices contentViewMoreProducts desktop:mr-8 bigdesktop:mr-40"
                  style={{ alignSelf: "center" }}
                >
                  <p className="textButtomGetTouch">
                    {i18n.t("ExsisAcademy.letHome")}
                  </p>
                </button>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            <div className="contentSecundaryOurExpertise2Mobile ml-4 mr-4">
              <div className="text-left leading-tight-desktop font-responsive-cards--desktop mt-4 text-16">
                {i18n.language == "en" ? (
                  <>
                    <p className="textOurExpertiseHomeMobile">
                      Let's Build your
                    </p>
                    <div className="flex">
                      <p className="textOurExpertiseHomeMobile">ne</p>
                      <p className="textOurExpertiseHomeMobile colorEx">x</p>
                      <p className="textOurExpertiseHomeMobile">t IT project</p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="textOurExpertiseHomeMobile">
                      Construyamos tu
                    </p>
                    <div className="flex">
                      <p className="textOurExpertiseHomeMobile">pró</p>
                      <p className="textOurExpertiseHomeMobile">x</p>
                      <p className="textOurExpertiseHomeMobile">imo</p>
                    </div>
                    <p className="textOurExpertiseHomeMobile">proyecto de TI</p>
                  </>
                )}
              </div>
              <p className="text_OurExpertiseMobile">
                {i18n.t("home.ourExpertiseHome.titleProject")}
              </p>
              <img src={liImage} alt="Clock" className="mt-8 " />
            </div>
            <div className="contentSecundaryOurExpertise2Mobile ml-4 mr-4">
              <img src={liImage2} alt="Clock" className="mb-8 mt-8" />
              <p className="textOurExpertiseHomeMobile text-left leading-tight-desktop font-responsive-cards--desktop mt-4 text-16">
                {i18n.t("home.ourExpertiseHome.titleCompany")}
              </p>
              <p className="text_OurExpertiseMobile">
                {i18n.t("home.ourExpertiseHome.textCompany")}
              </p>
              <div className="ml-auto">
                <button
                  onClick={() => window.location.href = 'https://calendly.com/sebastianjimenez/30min?back=1&month=2024-04'}
                  className="bottomLetsWork mt-8 desktop:mr-8 bigdesktop:mr-40"
                  style={{ alignSelf: "center" }}
                >
                  <p className="textbottom">{i18n.t("ExsisAcademy.letHome")}</p>
                </button>
              </div>
            </div>{" "}
          </>
        )}
      </div>
    </div>
  )
}

export default OurExpertiseHome
